<template>
  <div id="users_trainings_list">
    <div class="right div--button ma-2">
      Filtered rows: {{ users_length }}
    </div>
    <b-table :current-page="current_page"
             :fields="fields"
             :items="users"
             :per-page="per_page"
             class="table-responsive"
             head-variant="light"
             hover
             outlined
             responsive
             striped>

      <template v-slot:top-row="{fields}">
        <proposal_filter :id="id"
                         v-model="users"
                         :fields="fields"
                         :not_include_key="['actions','ordinalNumber']"/>
      </template>

      <template v-slot:cell(ordinalNumber)="row">
        <base-column-ordinal-number v-bind="{index: row.index, perPage: per_page, currentPage: current_page}"/>
      </template>

      <template v-slot:cell(training_data.safety_training.states.state)="row">
        <div v-if="hasProperty(row.item.training_data, 'safety_training')"
             class="center">
          <b-form-checkbox v-model="row.item.training_data.safety_training.states.state"
                           :disabled="true"/>
        </div>
        <div v-else
             class="center">
          <b-form-checkbox :disabled="true"
                           v-bind:value="false"/>
        </div>
      </template>

      <template v-slot:cell(actions)="row">
        <base-icon-button-send title="Go to user details"
                               @click="go_to_user(row.index)"/>
      </template>

    </b-table>

    <b_table_footer v-model="is_busy"
                    :currentPage="current_page"
                    :data_length="users_length"
                    :perPage="per_page"
                    @change_page="change_page">
    </b_table_footer>
  </div>
</template>

<script>
  import b_table_footer from '@/components/b_table_footer';
  import BaseIconButtonSend from '@/components/baseComponents/baseIcons/BaseIconButtonSend';
  import proposal_filter from '@/components/proposal_filter';
  import BaseColumnOrdinalNumber from '@/components/baseComponents/BaseColumnOrdinalNumber';

  export default {
    name: 'users_trainings_list',
    components: {
      BaseColumnOrdinalNumber,
      BaseIconButtonSend,
      proposal_filter,
      b_table_footer,
    },
    computed: {
      users_length() {
        return this.users.length;
      },
    },
    props: {
      fields: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        is_busy: false,
        users: [],
        current_page: 1,
        per_page: 20,
        id: 1,
      };
    },
    methods: {
      load_data() {
        this.toggle_busy();
        this.axios.get('/users/trainings')
          .then((response) => {
            this.users = response.data;
            this.$emit('setup_proposal', this.users);
            this.toggle_busy();
          });
      },
      change_page(val) {
        this.current_page = val;
      },
      toggle_busy() {
        this.is_busy = !this.is_busy;
      },
      calculate_row_index(index) {
        return index + (this.per_page * (this.current_page - 1));
      },
      go_to_user(index) {
        const idx = this.calculate_row_index(index);
        const user_id = this.users[idx]._id.$oid;
        this.$router.push(`/operational/users/${user_id}/1`);
      },
    },
    created() {
      this.load_data();
    },
  };
</script>

<style scoped>
  .center {
    text-align: center;
  }
</style>
