<template>
  <b-card bg-variant="light"
          header="Trainings"
          header-bg-variant="dark"
          header-text-variant="white">
    <b-tabs v-model="selected_card"
            card>

      <b-tab lazy
             title="User list">
        <users_trainings_list :fields="user_trainings_fields"></users_trainings_list>
      </b-tab>

    </b-tabs>
  </b-card>
</template>

<script>
  import users_trainings_list from '@/components/operational/trainings/users_trainings_list';
  import { ordinalNumber } from '@/helpers/commonTableColumns';

  export default {
    name: 'trainings_dashboard',
    components: { users_trainings_list },
    data() {
      return {
        selected_card: 1,
        user_trainings_fields: [
          ordinalNumber,
          {
            key: 'first_name',
            label: 'Name',
          },
          {
            key: 'last_name',
            label: 'Surname',
          },
          {
            key: 'email',
            label: 'Email',
          },
          {
            key: 'training_data.safety_training.states.state',
            label: 'Safety training (true/false)',
          },
          { key: 'actions' },
        ],
      };
    },
  };
</script>

<style scoped>
  .card {
    margin: 2rem;
  }
</style>
